import { LogoSvg } from '@/web/components/LogoSvg'
import { type SVGProps } from 'react'

export const LandingHeroIllustration = ({
  icon1,
  icon2,
  icon3,
  icon4,
  icon5,
  icon6,
  className,
  ...props
}: {
  icon1: JSX.Element
  icon2: JSX.Element
  icon3: JSX.Element
  icon4: JSX.Element
  icon5: JSX.Element
  icon6: JSX.Element
} & Partial<SVGProps<SVGSVGElement>>) => {
  return (
    <div className='rotate relative'>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox='-50 -60 450 510'
        className={className}
        {...props}>
        <g>
          <circle
            fill='rgba(255, 255, 255, 0.5)'
            cx='172.78'
            cy='0'
            r='48.15'
          />
          <circle
            fill='rgba(255, 255, 255, 0.5)'
            cx='172.78'
            cy='395.97'
            r='48.15'
          />
          <circle
            fill='rgba(255, 255, 255, 0.5)'
            cx='0'
            cy='101.32'
            r='48.15'
          />
          <circle
            fill='rgba(255, 255, 255, 0.5)'
            cx='345.56'
            cy='294.65'
            r='48.15'
          />
          <circle
            fill='rgba(255, 255, 255, 0.5)'
            cx='345.56'
            cy='101.32'
            r='48.15'
          />
          <circle
            fill='rgba(255, 255, 255, 0.5)'
            cx='0'
            cy='294.65'
            r='48.15'
          />
          <circle
            fill='rgba(255, 255, 255, 0.5)'
            cx='172.78'
            cy='197.59'
            r='93.7'
          />
          <circle
            fill='rgba(255, 255, 255, 0.5)'
            cx='172.78'
            cy='197.59'
            r='78.31'
          />
          <line
            stroke='white'
            strokeWidth='2'
            x1='172.78'
            y1='108.25'
            x2='172.78'
            y2='39.8'
          />
          <circle fill='#fff' cx='172.78' cy='0' r='39.79' />
          <line
            stroke='white'
            strokeWidth='2'
            x1='172.78'
            y1='287.72'
            x2='172.78'
            y2='356.18'
          />
          <circle fill='#fff' cx='172.78' cy='395.97' r='39.79' />
          <line
            stroke='white'
            strokeWidth='2'
            x1='94.47'
            y1='154.17'
            x2='34.73'
            y2='120.75'
          />
          <circle fill='#fff' cx='0' cy='101.32' r='39.79' />
          <line
            stroke='white'
            strokeWidth='2'
            x1='251.09'
            y1='241.8'
            x2='310.83'
            y2='275.22'
          />
          <circle fill='#fff' cx='345.56' cy='294.65' r='39.79' />
          <line
            stroke='white'
            strokeWidth='2'
            x1='251.09'
            y1='154.17'
            x2='310.83'
            y2='120.75'
          />
          <circle fill='#fff' cx='345.56' cy='101.32' r='39.79' />
          <line
            stroke='white'
            strokeWidth='2'
            x1='94.47'
            y1='241.8'
            x2='34.73'
            y2='275.22'
          />
          <circle fill='#fff' cx='0' cy='294.65' r='39.79' />
        </g>
      </svg>
      <div className='rotate-reverse absolute left-[6%] top-[26.5%] flex h-[10%] w-[10%] items-center justify-center'>
        {icon1 ?? null}
      </div>
      <div className='rotate-reverse absolute left-[44.5%] top-[6.8%] flex h-[10%] w-[10%] items-center justify-center'>
        {icon2 ?? null}
      </div>
      <div className='rotate-reverse absolute right-[7.2%] top-[26.5%] flex h-[10%] w-[10%] items-center justify-center'>
        {icon3 ?? null}
      </div>
      <div className='rotate-reverse absolute bottom-[25.5%] right-[7.2%] flex h-[10%] w-[10%] items-center justify-center'>
        {icon4 ?? null}
      </div>
      <div className='rotate-reverse absolute bottom-[5.5%] left-[44.5%] flex h-[10%] w-[10%] items-center justify-center'>
        {icon5 ?? null}
      </div>
      <div className='rotate-reverse absolute bottom-[25.5%] left-[6%] flex h-[10%] w-[10%] items-center justify-center'>
        {icon6 ?? null}
      </div>
      <div className='rotate-reverse absolute inset-0 m-auto flex w-[20%] items-center justify-center'>
        <LogoSvg isAnimated={false} className='w-full' />
      </div>
    </div>
  )
}
