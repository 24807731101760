import { EVENT } from '@/shared/constants/analytics'
import { WAITLIST_CREATE_SCHEMA } from '@/shared/constants/waitlist'
import { AnimateIn } from '@/web/components/AnimateIn'
import { Button } from '@/web/components/ui/button'
import { Input } from '@/web/components/ui/input'
import { identify, track } from '@/web/utils/analytics'
import { api } from '@/web/utils/api'
import { zodResolver } from '@hookform/resolvers/zod'
import { AnimatePresence } from 'framer-motion'
import { useForm, type SubmitHandler } from 'react-hook-form'
import { toast } from 'sonner'
import { z } from 'zod'

type FormFieldValues = { email: z.infer<typeof WAITLIST_CREATE_SCHEMA> }

export const WaitListForm = () => {
  const createWaitlistMutationResult = api.waitlist.create.useMutation({
    onError: err => {
      toast.error('Error joining waitlist', {
        description: err.message,
      })
    },
  })

  const form = useForm<FormFieldValues>({
    resolver: zodResolver(
      z.object({
        email: WAITLIST_CREATE_SCHEMA,
      })
    ),
    defaultValues: {
      email: '',
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
  })
  // HACK: Without this, the form state doesn't update after using autocomplete
  console.log('WaitListForm', form.formState)
  const canSubmit = form.formState.isValid && form.formState.isDirty

  const onSubmit: SubmitHandler<FormFieldValues> = (data, event) => {
    event?.preventDefault()

    console.log('Joining waitlist', data)

    identify(data.email, { name: data.email, email: data.email })
    track(EVENT.JOIN_WAITLIST, data)

    createWaitlistMutationResult.mutate(data.email)
    form.reset({}, { keepValues: true })
  }

  return (
    <AnimatePresence>
      {createWaitlistMutationResult.isSuccess ? (
        <AnimateIn as='h4' className='text-gradient' key='success'>
          We’ll be in touch soon!
        </AnimateIn>
      ) : (
        <AnimateIn
          key='form'
          onSubmit={form.handleSubmit(onSubmit)}
          as='form'
          className='flex flex-col flex-wrap gap-y-4 lg:flex-row lg:flex-nowrap'>
          <Input
            required
            type='email'
            placeholder='Enter your work email'
            size='xl'
            className='h-16 w-full [text-wrap:nowrap] focus-visible:ring-0 lg:[border-bottom-right-radius:0] lg:[border-top-right-radius:0]'
            {...form.register('email')}
          />
          <Button
            theme='premium'
            size='xl'
            className='h-16 min-w-fit [text-wrap:nowrap] lg:[border-bottom-left-radius:0] lg:[border-top-left-radius:0]'
            disabled={!canSubmit}
            type='submit'>
            {createWaitlistMutationResult.isLoading
              ? 'Saving...'
              : 'Get Early Access'}
          </Button>
        </AnimateIn>
      )}
    </AnimatePresence>
  )
}
