/// <reference types="next/image-types/global" />

import { SAMPLE_TASK_1 } from '@/shared/constants/demo'
import { AnimateIn } from '@/web/components/AnimateIn'
import { GmailIcon } from '@/web/components/icons/GmailIcon'
import { GoogleCalendarIcon } from '@/web/components/icons/GoogleCalendarIcon'
import { GoogleDriveIcon } from '@/web/components/icons/GoogleDriveIcon'
import { JiraIcon } from '@/web/components/icons/JiraIcon'
import { NotionIcon } from '@/web/components/icons/NotionIcon'
import { SlackIcon } from '@/web/components/icons/SlackIcon'
import { HowItWorksIllustration } from '@/web/components/illustrations/HowItWorks'
import { HowItWorks1 } from '@/web/components/illustrations/HowItWorks1'
import { HowItWorks2 } from '@/web/components/illustrations/HowItWorks2'
import { HowItWorks3 } from '@/web/components/illustrations/HowItWorks3'
import { LandingHeroIllustration } from '@/web/components/illustrations/LandingHeroIllustration'
import { LandingFeatures } from '@/web/components/LandingFeatures'
import { LandingPrivacy } from '@/web/components/LandingPrivacy'
import { LandingSection } from '@/web/components/LandingSection'
import { LogoSvg } from '@/web/components/LogoSvg'
import { TaskCard } from '@/web/components/TaskCard'
import { WaitListForm } from '@/web/components/WaitListForm'
import { useRef, type Ref } from 'react'

export default function HomePage() {
  const scrollRef = useRef<HTMLElement>(null)

  // @see https://wave.novoselski.net/
  return (
    <div className='flex flex-col gap-24 pb-10 sm:gap-40 xl:pb-40'>
      <main
        className='wavy-border-bottom bg-base-300'
        ref={scrollRef as Ref<HTMLDivElement>}>
        <LandingSection
          id='hero'
          className='mx-auto flex min-h-[80dvh] max-w-3xl flex-col-reverse flex-wrap items-center justify-center gap-4 px-4 pb-8 text-center md:max-w-4xl md:flex-row md:gap-12 md:py-12 md:text-left xl:max-w-6xl xl:flex-nowrap xl:pb-20 2xl:max-w-7xl'>
          <AnimateIn className='flex-[1.6]'>
            <h1 className='text-black'>
              Prioritize Your Notifications And Tasks In One Place
            </h1>
            <h4 className='text-muted-foreground mt-6'>
              Pulse cuts through the noise and surfaces your priority items,
              eliminating the clutter of multiple apps and constant
              context-switching.
            </h4>
            <section className='pt-10'>
              <WaitListForm />
            </section>
          </AnimateIn>
          <AnimateIn className='max-w-[62.5%] md:flex-1 lg:max-w-full xl:px-12'>
            <LandingHeroIllustration
              className='w-full overflow-hidden'
              icon1={<SlackIcon isColored className='h-full w-full' />}
              icon2={<GoogleCalendarIcon isColored className='h-full w-full' />}
              icon3={<GmailIcon isColored className='h-full w-full' />}
              icon4={<NotionIcon isColored className='h-full w-full' />}
              icon5={<JiraIcon isColored className='h-full w-full' />}
              icon6={<GoogleDriveIcon isColored className='h-full w-full' />}
            />
          </AnimateIn>
        </LandingSection>
      </main>

      <div className='flex flex-col gap-8 px-4 sm:px-8 xl:gap-20 '>
        <AnimateIn className='mx-auto flex max-w-3xl flex-col-reverse items-center justify-between gap-8 text-center sm:flex-row sm:gap-20 sm:text-left md:grid-cols-2 xl:max-w-6xl 2xl:max-w-7xl'>
          <div className='flex flex-1 flex-col gap-6'>
            <h1>
              What is <span className='text-gradient'>pulse</span>?
            </h1>
            <h4 className='text-muted-foreground'>
              Pulse isn’t just your average to-do list. It also{' '}
              <strong className='text-turquoise'>
                prioritizes notifications
              </strong>{' '}
              from all of your tools into{' '}
              <strong className='text-violet'>actionable tasks</strong> and
              creates <strong className='text-pink'>automated workflows</strong>{' '}
              to complete them.
            </h4>
          </div>
          <LogoSvg isAnimated className='mx-auto min-w-[150px] max-w-[17vw]' />
        </AnimateIn>

        <AnimateIn className='w-full max-w-4xl self-center'>
          <TaskCard isDemo task={SAMPLE_TASK_1} />
        </AnimateIn>
      </div>

      <div className='flex flex-col gap-12 xl:gap-48'>
        <AnimateIn className='flex flex-col gap-8 text-center md:gap-0'>
          <h1>
            How it <span className='text-gradient'>works</span>
          </h1>
          <div className='hidden md:block'>
            <HowItWorksIllustration />
          </div>
        </AnimateIn>
        <AnimateIn className='mr-auto grid w-full max-w-6xl grid-cols-1 flex-wrap place-items-center items-center justify-center gap-4 px-8 text-center sm:gap-8 md:text-left md:[grid-template-columns:clamp(300px,10vw,600px)_1fr]'>
          <HowItWorks1 className='w-full max-w-xs' />
          <div className='flex flex-1 flex-col gap-6'>
            <h3>Connect all your tools</h3>
            <h6 className='text-muted-foreground w-full text-[22.5px]'>
              Pulse is made to work with all of your core tools so you don’t
              miss anything. Simply sign up and then click on “Connect to
              Slack/Gmail/Google Calendar/Jira/Notion/etc.”
            </h6>
          </div>
        </AnimateIn>
        <AnimateIn className='mx-auto grid w-full max-w-6xl grid-cols-1 flex-wrap place-items-center items-center justify-center gap-4 px-8 text-center sm:gap-8 md:text-left md:[grid-template-columns:clamp(300px,10vw,600px)_1fr]'>
          <HowItWorks2 className='w-full max-w-xs' />
          <div className='flex flex-1 flex-col gap-6'>
            <h3>Convert your notifications</h3>
            <h6 className='text-muted-foreground w-full text-[22.5px]'>
              Pulse continuously monitors your tools for incoming notifications.
              It filters out the noise and converts what you need to know into
              bite-sized tasks.
            </h6>
          </div>
        </AnimateIn>
        <AnimateIn className='ml-auto grid w-full max-w-6xl grid-cols-1 flex-wrap place-items-center items-center justify-center gap-4 px-8 text-center sm:gap-8 md:text-left md:[grid-template-columns:clamp(300px,10vw,600px)_1fr]'>
          <HowItWorks3 className='w-full max-w-xs' />
          <div className='flex flex-1 flex-col gap-6'>
            <h3>Complete your tasks</h3>
            <h6 className='text-muted-foreground w-full text-[22.5px]'>
              Pulse makes it easy to fly through your reactive work. Reply to
              messages and emails with a single click, schedule meetings without
              entering any details, update your projects with notes from your
              scattered conversations, and more. All in one place.
            </h6>
          </div>
        </AnimateIn>
      </div>

      <LandingFeatures />

      <LandingPrivacy />

      {/* <AnimateIn>
        <h6 className='text-muted-foreground pb-6 pt-12 text-center 2xl:pb-12'>
          Integrated with the tools you depend on
        </h6>
        <div className='text-neutral-900'>
          <LogoCloud />
        </div>
      </AnimateIn> */}
    </div>
  )
}
